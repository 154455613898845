import React from "react"

const GoogleReviewsLp = (props) => {
    return (
        <div className="pb-24 relative">
            <div className="container mx-auto px-5 max-w-2xl text-center leading-7">
                <div className="px-5">
                   <div src='https://cdn.trustindex.io/loader.js?3a7859b434128779cc26d1a50e5'></div>
                </div>
            </div>
        </div>
    )
}
export default GoogleReviewsLp;
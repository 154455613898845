import React, { useState } from "react"
import Modal from "react-modal"
import { graphql, useStaticQuery } from "gatsby"

export default function CookiePopup() {
    const data = useStaticQuery(graphql`
        query {
            allContentfulShippingReturnsPopup {
                edges {
                    node {
                        title
                        content {
                        textBeforeAddress
                        companyName
                        streetAddress
                        cityStateZip
                        textAfterAddress
                        }
                    }
                }
            }
        }
    `)

    const popupData = data.allContentfulShippingReturnsPopup.edges[0].node
    const [isOpen, setIsOpen] = useState(false)

    function toggleModal() {
        setIsOpen(!isOpen)
    }

    const style = {
        content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '12px',
            outline: 'none',
            padding: '0px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-40%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '500px',
            height: '100%'
        },
        overlay: {
            backdropFilter: 'blur(1px)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 1000
        }
    }

    return (
        <div className="container">
            <button
                className="pl-4 text-gray-400 hover:text-gray-300 underline transition duration-150 ease-in-out"
                type="button"
                title="Information On Shipping & Returns"
                alt="Information On Shipping & Returns"
                onClick={toggleModal}
            >
                Shipping & Returns
            </button>
            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={toggleModal}
                    contentLabel="My dialog"
                    style={style}
                >
                    {isOpen ? (
                        <div className="text-center max-w-3xl mx-auto">
                            <div className="p-5">
                                {/*header*/}
                                <div className="pb-5 border-b border-solid border-blueGray-200">
                                    <p className="text-2xl font-semibold mx-auto">
                                        {popupData.title}
                                    </p>
                                </div>
                                <div className="fixed top-5 right-5">
                                    <button className="text-lg lg:text-2xl font-bold hover:text-lndred transition duration-150 ease-in-out" title="Close Pop-up" alt="Close Pop-up" onClick={toggleModal}>X</button>
                                </div>
                                {/*body*/}
                                <div className="py-5 text-blueGray-500 text-lg leading-relaxed">
                                    <p>{popupData.content[0].textBeforeAddress}</p>
                                    {/* <br /><br /><b>
                                            L & D Solutions Inc.<br />
                                            7255 32nd St.<br />
                                            North Highlands, CA 95660
                                            <br /><br /></b> */}
                                    {popupData.content.map(address =>
                                        <div key={address.streetAddress} className="font-bold py-3">
                                            <p>{address.companyName}</p>
                                            <p>{address.streetAddress}</p>
                                            <p>{address.cityStateZip}</p>
                                        </div>
                                    )}
                                    <p>{popupData.content[0].textAfterAddress}</p>
                                </div>
                                {/*footer*/}
                                <div className="border-t border-solid border-blueGray-200 pt-5">
                                    <div className="">
                                        <button
                                            className="relative h-10 py-2 px-8 mx-auto transition-colors rounded-lg border border-lndred focus:shadow-outline bg-lndred text-white hover:shadow-lg hover:shadow-lndred font-semibold transition duration-150 ease-in-out"
                                            type="button"
                                            title="Close Pop-up"
                                            alt="Close Pop-up"
                                            onClick={() => toggleModal()}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </Modal>
            </div>
        </div>
    )
}